export const closeToDeadline = (dateString, status) => {
  if (status === "done") {
    return false;
  }
  const dateToCompare = new Date();
  dateToCompare.setDate(dateToCompare.getDate() + 3);

  const date = new Date(dateString);
  if (dateToCompare.getTime() >= date.getTime()) {
    return true;
  }
  return false;
};

export const getISODate = (date) => {
  if (!date) return;

  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  if (month < 10) month = `0${month}`;
  if (day < 10) day = `0${day}`;

  return `${year}-${month}-${day}`;
};

export const getLocaleDate = (locale, dateString) => {
  if (!dateString) return;
  return new Date(dateString).toLocaleDateString(locale, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

export const getMonthAndYear = (locale, date) => {
  return date.toLocaleDateString(locale, {
    year: "numeric",
    month: "long",
  });
};

export const getMonth = (dateString) => {
  if (!dateString) return;

  return new Date(dateString).getMonth();
};

export const getYear = (dateString) => {
  if (!dateString) return;

  return new Date(dateString).getFullYear();
};
