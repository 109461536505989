<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">
      {{ t("headings.project") }}
    </h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card mb-4 p-4">
      <div class="grid grid-cols-1 gap-x-12 gap-y-4 p-4 md:grid-cols-2">
        <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
          <div>
            <label class="block">{{ t("labels.name") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ project.name }}</span>
          </div>
        </div>
        <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
          <div>
            <label class="block">{{ t("labels.created_at") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ getLocaleDate(locale, project.createdAt) }}</span>
          </div>
        </div>
        <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
          <div>
            <label class="block">{{ t("labels.estate_name") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else-if="project.estate"> {{ project.estate.name }}</span>
          </div>
        </div>
        <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
          <div>
            <label class="block">{{ t("labels.status") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else> {{ t("status." + project.status) }}</span>
          </div>
        </div>
      </div>

      <hr class="my-4 border-slate-300" />
      <div class="flex justify-end">
        <form-button
          :command="() => router.push(`/projects/${project.id}/edit`)"
          :disabled="waiting"
          id="project-edit-button"
          label="edit"
          :textVariant="company.primaryText"
          type="button"
          :variant="company.primary"
        />
      </div>
    </div>
    <h2 class="mb-2">{{ t("headings.phases_tasks") }}</h2>
    <div class="card">
      <div class="p-4">
        <div class="overflow-x-auto">
          <g-gantt-chart
            style="min-width: 1500px"
            :chart-start="getStartDate(project.phases)"
            :chart-end="getEndDate(project.phases)"
            precision="day"
            :push-on-overlap="true"
            bar-start="myBeginDate"
            bar-end="myEndDate"
          >
            <template #upper-timeunit="{ date }">
              <span class="capitalize">{{
                getMonthAndYear(locale, new Date(date))
              }}</span>
            </template>
            <template #timeunit="{ value }">
              {{ value }}
            </template>
            <template #bar-tooltip="{ bar }">
              {{ getLocaleDate(locale, bar.myBeginDate) }} -
              {{ getLocaleDate(locale, bar.myEndDate) }}
            </template>
            <g-gantt-row
              v-for="(phase, index) of project.phases"
              :key="index"
              :label="phase.name"
              :bars="bars[phase.name]"
            />
          </g-gantt-chart>
        </div>
        <div class="flex justify-end py-4">
          <form-button
            :command="() => saveTimes()"
            :disabled="waiting"
            id="save-task-times-button"
            label="save_times"
            :textVariant="company.primaryText"
            type="button"
            :variant="company.primary"
          />
        </div>
        <hr class="py-8" />
      </div>
      <custom-table
        :expandedItems="expandedItems"
        :fields="fields"
        :items="project.phases ? project.phases : []"
        :loading="waiting"
        :pagination="true"
        :perPage="10"
        :searchable="true"
        :searchFields="searchFields"
        :showHeader="true"
        sortBy="startDate"
        :ascending="false"
        :sortFields="sortFields"
        @row-click="(id) => toggleExpand(id)"
      >
        <template #header>
          <span class="flex justify-end"
            ><form-button
              :command="
                () => router.push(`/projects/${project.id}/phases/create`)
              "
              id="header-create-phase-button"
              label="create_phase"
              :textVariant="company.primaryText"
              type="button"
              :variant="company.primary"
          /></span>
        </template>
        <template #expand="{ value }">
          <div class="bg-slate-100 px-3 py-8">
            <h3 class="px-4">{{ t("headings.tasks") }}</h3>
            <custom-table
              :fields="taskFields"
              :items="value.tasks || []"
              :loading="waiting"
              :pagination="true"
              :perPage="10"
              :searchable="false"
              :searchFields="[]"
              :showHeader="false"
              sortBy="startDate"
              :ascending="false"
              :sortFields="[]"
              @row-click="
                (id) =>
                  router.push(`/projects/${project.id}/${value.name}/${id}`)
              "
            >
              <template #cell(startDate)="{ value }">
                {{ getLocaleDate(locale, value) }}
              </template>
              <template #cell(endDate)="{ value, item }">
                <span
                  :class="{
                    'text-rose-600': closeToDeadline(value, item.status),
                  }"
                  >{{ getLocaleDate(locale, value) }}</span
                >
              </template>
              <template #cell(status)="{ value }">
                {{ t("status." + value) }}
              </template>
            </custom-table>
          </div>
        </template>
        <template #cell(tasks)="{ value }">
          {{ value.length }}
        </template>
        <template #cell(startDate)="{ value }">
          {{ getLocaleDate(locale, value) }}
        </template>
        <template #cell(endDate)="{ value }">
          {{ getLocaleDate(locale, value) }}
        </template>
        <template #cell(action)="{ item }">
          <div class="flex justify-end">
            <form-button
              :command="
                () =>
                  router.push(
                    `/projects/${project.id}/${item.name}/create-task`
                  )
              "
              id="table-create-task-button"
              label="create_task"
              :textVariant="company.primaryText"
              type="button"
              :variant="company.primary"
            >
            </form-button>
          </div>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import Skeleton from "../components/loading/Skeleton.vue";
import CustomTable from "../components/table/CustomTable.vue";
import {
  closeToDeadline,
  getLocaleDate,
  getMonthAndYear,
} from "../utils/dateUtils";

export default {
  components: { Breadcrumb, CustomTable, FormButton, Skeleton },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const company = computed(() => store.state.company.company);
    const project = computed(() => store.state.project.project);
    const tasks = computed(() => store.state.task.projectTasks);

    const waiting = computed(() => {
      if (store.state.project.waiting) return true;
      if (store.state.task.waiting) return true;
      return false;
    });

    const fields = ref([
      {
        key: "name",
        label: "phase",
      },
      {
        key: "tasks",
        label: "tasks",
      },
      {
        key: "startDate",
        label: "start_date",
      },
      {
        key: "endDate",
        label: "end_date",
      },
      {
        key: "action",
        label: "empty",
      },
    ]);
    const searchFields = ref(["name", "startDate", "endDate"]);
    const sortFields = ref(["name", "startDate", "endDate"]);

    const taskFields = ref([
      {
        key: "name",
        label: "name",
      },
      {
        key: "startDate",
        label: "start_date",
      },
      {
        key: "endDate",
        label: "end_date",
      },
      {
        key: "status",
        label: "status",
      },
      {
        key: "action",
        label: "empty",
      },
    ]);

    const getStartDate = (phases) => {
      let earliest;
      if (!phases || !phases.length) {
        earliest = new Date(new Date().setMonth(new Date().getMonth() - 1))
          .toISOString()
          .split("T")[0];
      } else {
        for (const phase of phases) {
          if (!earliest) {
            earliest = phase.startDate;
          } else if (earliest > phase.startDate) {
            earliest = phase.startDate;
          }
        }
      }
      return earliest + " 12:00";
    };

    const getEndDate = (phases) => {
      let latest;
      if (!phases || !phases.length) {
        latest = new Date(new Date().setMonth(new Date().getMonth() + 1))
          .toISOString()
          .split("T")[0];
      } else {
        for (const phase of phases) {
          if (!latest) {
            latest = phase.endDate;
          } else if (latest < phase.endDate) {
            latest = phase.endDate;
          }
        }
      }
      return latest + " 12:00";
    };

    const bars = ref({});

    const setBars = (phases) => {
      bars.value = {};
      for (const phase of phases) {
        bars.value[phase.name] = [];
        if (phase.tasks && phase.tasks.length) {
          for (const task of phase.tasks) {
            bars.value[phase.name].push({
              myBeginDate: task.startDate + " 12:00",
              myEndDate: task.endDate + " 12:00",
              ganttBarConfig: {
                hasHandles: true,
                id: task.id,
                isTask: true,
                label: task.name,
                style: {
                  backgroundColor: company.value.primary[500],
                  color: company.value.primaryText,
                  borderRadius: "4px",
                },
              },
            });
          }
        } else {
          bars.value[phase.name].push({
            myBeginDate: phase.startDate + " 12:00",
            myEndDate: phase.endDate + " 12:00",
            ganttBarConfig: {
              hasHandles: false,
              id: phase.name,
              isTask: false,
              label: phase.name,
              style: {
                backgroundColor: company.value.primary[500],
                color: company.value.primaryText,
                borderRadius: "4px",
              },
            },
          });
        }
      }
      return bars;
    };

    const saveTimes = () => {
      const tasks = [];
      for (const phase of Object.values(bars.value)) {
        for (const task of phase) {
          if (task.isTask) {
            tasks.push({
              id: task.ganttBarConfig.id,
              startDate: task.myBeginDate.split(" ")[0],
              endDate: task.myEndDate.split(" ")[0],
            });
          }
        }
      }
      store.dispatch("task/saveTimes", tasks);
    };

    const expandedItems = ref([]);

    const toggleExpand = (index) => {
      const idx = expandedItems.value.findIndex(
        (itemIndex) => itemIndex == index
      );
      if (idx > -1) {
        expandedItems.value.splice(idx, 1);
      } else {
        expandedItems.value.push(index);
      }
    };

    onMounted(() => {
      store.dispatch("project/getProject", route.params.projectId);
    });

    watch(
      () => project.value,
      (project) => {
        if (project.phases && project.phases.length) {
          setBars(project.phases);
        }
      }
    );

    return {
      bars,
      closeToDeadline,
      company,
      expandedItems,
      fields,
      getEndDate,
      getLocaleDate,
      getMonthAndYear,
      getStartDate,
      locale,
      project,
      route,
      router,
      saveTimes,
      searchFields,
      sortFields,
      t,
      taskFields,
      tasks,
      toggleExpand,
      waiting,
    };
  },
};
</script>
